$(document).ready(function() {
    if ($(window).scrollTop() > 150) {
        $('#header').addClass('dark-bg');
    } else {
        $('#header').removeClass('dark-bg');
    }

    if ($(window).scrollTop() > 250) {
        $('#to-top').css('opacity', 1);
    } else {
        $('#to-top').css('opacity', 0);
    }


    $(window).on('scroll', function(e) {
        if ($(window).scrollTop() > 150) {
            $('#header').addClass('dark-bg');
        } else {
            $('#header').removeClass('dark-bg');
        }

        if ($(window).scrollTop() > 250) {
            $('#to-top').css('opacity', 1);
        } else {
            $('#to-top').css('opacity', 0);
        }
    });

    $('.menu .switcher').on('click', function() {
        $(this).toggleClass('closed');

        $('.menu .inner-menu').toggleClass('open-menu');

        return false;
    });

    $('#to-top').on('click', function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");

        return false;
    });

    $('#send-contact-form').on('click', function() {
        const form  = $(this).closest('form');
        const name  = form.find('[name="name"]').val();
        const email = form.find('[name="email"]').val();
        const phone = form.find('[name="phone"]').val();
        const text  = form.find('[name="text"]').val();

        if (email.trim() && text.trim()) {
            $.ajax({
                url:  '/modules/send.php',
                type: 'POST',
                data: {name: name, email: email, text: text, phone: phone},
                success: function() {
                    showSuccess('Success! Your message has been successfully sent!');
                }
            });
        }
    });

    $(document).on('click', function(e) {
        if ($(e.target).closest('#dummy').length && !$(e.target).closest('#modal').length) {
            hideDummy();
        }
    });

    $('[data-buy-plan]').on('click', function() {
        const plan = $(this).attr('data-buy-plan');
        const page = $(this).attr('data-buy-page');

        if (page === 'vpn') {
            showDummy('<iframe frameborder="0" src="/vpn/vpn.php?plan=' + plan + '"></iframe>');
        } else {
            showDummy('<iframe frameborder="0" src="/vpn/other.php?type=' + page + '&plan=' + plan + '"></iframe>');
        }
    });

    $('[data-tempid]').on('click', function() {
        const tempid = $(this).attr('data-tempid');

        location.href = '/templates.php?temp_id=' + tempid;
        return false;

        $.ajax({
            url:  '/modules/get_template.php',
            data: {temp_id: tempid},
            type: 'POST',
            success: function(result) {
                showDummy('<div style="height:100%;overflow:auto;">'+result+'</div>');
            }
        });

        return false;
    });

    $('[data-to-info]').on('click', function() {
        $('html, body').animate({
            scrollTop: $(".inner-info").offset().top - 100
        }, 500);

        return false;
    });

    $('[data-to-price]').on('click', function() {
        $('html, body').animate({
            scrollTop: $(".pricing-block").offset().top
        }, 500);

        return false;
    });

    function showSuccess(text) {
        const success = $('#success');

        success.html(text);

        success.css({top: '80px', opacity: 1});

        setTimeout(function() {
            success.css({top: '40px', opacity: 0});
        }, 2000);
    }

    function showDummy(content) {
        const dummy = $('#dummy');

        dummy.find('#modal').html(content);

        dummy.css({display: 'block'});

        setTimeout(function() {
            dummy.css({opacity: 1});
        }, 10);
    }

    function hideDummy() {
        const dummy = $('#dummy');

        dummy.css({opacity: 0});

        setTimeout(function() {
            dummy.css({'display': 'none'});
        }, 500);
    }
});